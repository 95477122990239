import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  ListSubheader,
  makeStyles,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core"
import { Delete, Save } from "@material-ui/icons"
import { IncomingJobMapInterface, MapTypeEnum } from "models/MCP/DenaliMaps"
import { Department } from "models/MCP/Department"
import { ICommunity } from "models/MCP/ICommunity"
import React, { FC, useState } from "react"
import "react-datepicker/dist/react-datepicker.css"
import { styles } from "../../css/shared-css"
import { ConfirmDialog } from "components/ConfirmDialog"

const useStyles = makeStyles({
  saveButton: {
    marginLeft: "0.5em"
  },
  existingIdRow: {
    backgroundColor: "auto"
  },
  newIdRow: {
    backgroundColor: "#ffe345"
  },
  dialogText: {
    lineHeight: 1,
    margin: 0,
    marginTop: "0.5rem"
  }
})

interface IncomingJobMapRowProps {
  mapping: IncomingJobMapInterface
  index: number
  communityFilteredOptions: any[]
  modified: boolean
  type: any
  communities: ICommunity[] | undefined
  showSave: boolean
  applyToAllCommunities: boolean
  filteredByCompany: () => boolean
  onChange: (key: any, mapping: IncomingJobMapInterface) => void
  onDelete: (key: any, deletedMapping: IncomingJobMapInterface, deleteAllMatching: boolean) => void
  onSave: (key: any, mapping: IncomingJobMapInterface) => void
}

export const IncomingJobMapRow: FC<IncomingJobMapRowProps> = ({
  mapping,
  index,
  communityFilteredOptions,
  communities,
  modified,
  type,
  showSave,
  applyToAllCommunities,
  filteredByCompany,
  onChange,
  onDelete,
  onSave
}) => {
  const classes = useStyles()
  const [confirmDeleteAllMatching, setConfirmDeleteAllMatching] = useState<boolean>(false)

  const labels = {
    customer: ["Job Code", "Job Code", "Pay Code", "FLSA Code", "Facility Id"],
    companyCode: ["Employee Job Code", "Punch Job Code", "Punch Pay Code", "FLSA Type", "Community"]
  }

  const listGroup = (department: Department, type: MapTypeEnum) => {
    let jobFunctions
    if (type === MapTypeEnum.FlsaCode || type === MapTypeEnum.PunchPayCode) {
      return (
        <MenuItem key={`${index}.${department.id}`} value={department.id}>
          {department.name}
        </MenuItem>
      )
    } else {
      jobFunctions = department.jobFunctions.map((jobFunction) => (
        <MenuItem key={`${index}.${department.id}.${jobFunction.id}`} value={jobFunction.id}>
          {jobFunction.name}
        </MenuItem>
      ))
      const header = <ListSubheader key={`${index}.${department.id}`}>{department.name}</ListSubheader>
      return [header, jobFunctions]
    }
  }

  return (
    <React.Fragment key={`${index}.mapRow`}>
      <Grid
        key={`${index}.customerCodeGridItem`}
        item
        xs={4}
        id="parent"
        className={mapping.id && !modified ? classes.existingIdRow : classes.newIdRow}
      >
        <div key={`${index}.customerCodeDiv`} style={styles.box} id="customerCode">
          <TextField
            key={`${index}.customerCode`}
            value={mapping.customerCode}
            onChange={(e) => {
              let updatedMapping = { ...mapping, customerCode: e.target.value }
              onChange(index, updatedMapping)
            }}
            label={`Customer ${labels.customer[type]}`}
            variant="outlined"
          ></TextField>
        </div>
      </Grid>
      <Grid
        key={`${index}.mappedValueGridItem`}
        item
        xs={4}
        className={mapping.id && !modified ? classes.existingIdRow : classes.newIdRow}
      >
        <div key={`${index}.mappedValueDiv`} style={styles.box} id="mappedValue">
          <FormControl key={`${index}.mappedValueFormControl`} fullWidth variant="outlined">
            <InputLabel key={`${index}.mappedValueLabel`} id={`mappedValue.${labels.companyCode[type]}`}>
              {labels.companyCode[type]}
            </InputLabel>
            <Select
              key={`${index}.tableId`}
              labelId="mappedValueLabelId"
              id="mappedValueId"
              fullWidth
              value={mapping.tableId}
              defaultValue=""
              onChange={(e) => {
                let updatedMapping = { ...mapping, tableId: String(e.target.value) }
                onChange(index, updatedMapping)
              }}
              label={labels.companyCode[type]}
            >
              {!filteredByCompany()
                ? communityFilteredOptions?.map((selectorItem) => listGroup(selectorItem, type))
                : communities?.map((community) => (
                    <MenuItem key={`${index}.${community.id}`} value={community.id}>
                      {community.name}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </div>
      </Grid>
      <Grid key={`${index}.actionsGridItem`} item xs={4}>
        <div key={`${index}.actionsDiv`} style={styles.box}>
          {showSave && (
            <Button
              key={`${index}.saveButton`}
              variant="contained"
              color="primary"
              onClick={() => onSave(index, mapping)}
              className={classes.saveButton}
            >
              <Save />
            </Button>
          )}
          {(!applyToAllCommunities || (applyToAllCommunities && !modified)) && (
            <Button
              key={`${index}.deleteButton`}
              variant="contained"
              color="primary"
              onClick={() => {
                if (applyToAllCommunities) {
                  setConfirmDeleteAllMatching(true)
                } else {
                  onDelete(index, mapping, false)
                }
              }}
              className={classes.saveButton}
            >
              <Delete />
            </Button>
          )}
          <ConfirmDialog
            key={`${index}.confirmationDialog`}
            title="Delete All Matching Mappings?"
            open={confirmDeleteAllMatching}
            setOpen={setConfirmDeleteAllMatching}
            onConfirm={() => onDelete(index, mapping, true)}
          >
            <>
              <p className={classes.dialogText}>
                Deleting this mapping will also remove matching customer value mappings from all communities.
              </p>
              <p className={classes.dialogText}>Are you sure you want to continue?</p>
            </>
          </ConfirmDialog>
        </div>
      </Grid>
    </React.Fragment>
  )
}
