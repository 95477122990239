import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Paper, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { styles } from "css/shared-css"
import { MapTypeEnum } from "models/MCP/DenaliMaps"
import { ICommunity } from "models/MCP/ICommunity"
import { ICompany } from "models/MCP/ICompany"
import React, { FC, useCallback, useEffect, useState } from "react"
import { McpApiService } from "services/McpApiService"
import { CompanySelector } from "./CompanySelector"

const pageStyles: { [name: string]: React.CSSProperties } = {
  csvButton: {
    display: "inline-block",
    paddingRight: "1rem",
    paddingTop: "0.8rem"
  },
  csvLabel: {
    display: "inline-block"
  }
}

export interface CommunitySelectorProps {
  company: ICompany | null
  setCompany: (company: ICompany | null) => void
  type: any
  community: ICommunity | null
  setCommunity: (community: ICommunity | null) => void
  setAffectsAllCommunities: (affectsAllCommunities: boolean) => void
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  enableUploadButton: boolean
}

export const CommunitySelector: FC<CommunitySelectorProps> = ({
  company,
  setCompany,
  type,
  community,
  setCommunity,
  setAffectsAllCommunities,
  onFileChange,
  enableUploadButton
}) => {
  const [userCommunities, setUserCommunities] = useState<ICommunity[]>([])
  const [isLoadingCommunity, setIsLoadingCommunity] = useState(false)
  const [applyToAllCommunities, setApplyToAllCommunties] = useState(false)

  const fetchCommunities = async (companyId: string) => {
    setIsLoadingCommunity(true)
    var userAvailableCommmunities = await McpApiService.GetUserCommunitiesByCompany(companyId).finally(() => {
      setIsLoadingCommunity(false)
    })
    setUserCommunities(userAvailableCommmunities ?? [])
    if (userAvailableCommmunities.length === 1) {
      setCommunity(userAvailableCommmunities[0])
    }
  }

  useEffect(() => {
    if (company !== null) {
      fetchCommunities(company.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company])

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Set the internal state and also tell the parent component about the updated state.
    setApplyToAllCommunties(event.target.checked)

    setAffectsAllCommunities(event.target.checked)
  }

  // Need to clear out the file picker value ever time the file is selected.
  // If this is not done then the same file cannot be re-selected and process the "change" event.
  // This is an issue in Chromium browsers.
  const fileClick = useCallback((event: React.MouseEvent<HTMLInputElement>) => {
    event.currentTarget.value = null as any
  }, [])

  return (
    <Paper>
      <Grid container>
        <Grid item xs={6}>
          <CompanySelector company={company} setCompany={setCompany} />
        </Grid>

        {type !== MapTypeEnum.FacilityMapping && (
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={applyToAllCommunities}
                  onChange={handleCheckBoxChange}
                  name="checkedA"
                  color="primary"
                />
              }
              label="Apply To All Communities"
              style={{ paddingTop: "1rem" }}
            />
          </Grid>
        )}

        {type !== MapTypeEnum.FacilityMapping && (
          <Grid item xs={6}>
            <Box style={styles.box}>
              <FormControl style={styles.fullLength}>
                <Autocomplete
                  id="community"
                  value={community}
                  loading={isLoadingCommunity}
                  options={userCommunities}
                  getOptionLabel={(option) => option.name}
                  disabled={company == null || applyToAllCommunities}
                  onChange={(e, value) => setCommunity(value)}
                  renderInput={(params) => (
                    <TextField {...params} style={styles.fullLength} label="Community" variant="outlined" />
                  )}
                />
              </FormControl>
            </Box>
          </Grid>
        )}

        <Grid item xs={6}>
          <div style={pageStyles.csvButton}>
            <Button variant="contained" component="label" color="primary" disabled={!enableUploadButton}>
              Upload File
              <input
                type="file"
                style={{ display: "none" }}
                accept=".csv"
                onClick={fileClick}
                onChange={onFileChange}
              />
            </Button>
          </div>
        </Grid>
      </Grid>
    </Paper>
  )
}
