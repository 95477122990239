import { Backdrop, Button, CircularProgress, Grid, Typography } from "@material-ui/core"
import { Add, Save } from "@material-ui/icons"
import { AxiosError } from "axios"
import { ConfirmDialog } from "components/ConfirmDialog"
import WithErrorHandling from "components/error-banner"
import { IBulkRequest } from "models/IBulkRequest"
import {
  APIMapInterface,
  FacilityInterface,
  FlsaCodeInterface,
  IncomingJobMapInterface,
  JobCodeInterface,
  MapTypeEnum,
  PayCodeInterface
} from "models/MCP/DenaliMaps"
import { Department } from "models/MCP/Department"
import { ICommunity } from "models/MCP/ICommunity"
import { ICompany } from "models/MCP/ICompany"
import Papa from "papaparse"
import React, { FC, useCallback, useMemo, useState } from "react"
import "react-datepicker/dist/react-datepicker.css"
import { useHistory } from "react-router-dom"
import { McpApiService } from "services/McpApiService"
import { McpApiUtils } from "utils/McpApiServiceUtils"
import { CommunitySelector } from "../../components/CommunitySelector"
import { styles } from "../../css/shared-css"
import { IncomingJobMapRow } from "./IncomingJobMapRow"

/*

This component is meant to be used for incoming job mappings: EmployeeJobCode, 
PunchJobCode, FacilityId and FlsaCode. Any additional mappings added will have to be first 
added to the MapTypeEnum type. If the mapping being added requires filtering at a company 
level add it to the filteredByCompany() below and follow the model for the facility enumType
in the api services
-------------------------------------------------------------------------------------------
*/

const pageStyles: { [name: string]: React.CSSProperties } = {
  mainPage: {
    height: "calc(100vh - 65px)"
  },
  topSelector: {
    height: "13rem"
  },
  topSelectorFacilities: {
    height: "7rem"
  },
  mappingSection: {
    height: "calc(100% - 17rem)",
    overflowY: "auto"
  },
  mappingSectionFacilities: {
    height: "calc(100% - 11rem)",
    overflowY: "auto"
  },
  bottomBar: {
    height: "4rem",
    borderRadius: "4px",
    color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: "#fff",
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"
  },
  saveAllRowsButton: {
    marginLeft: "8px"
  },
  backdrop: {
    zIndex: 9999,
    color: "#fff"
  },
  dialogText: {
    lineHeight: 1,
    margin: 0,
    marginTop: "0.5rem"
  }
}

export const IncomingJobMap: FC<any> = () => {
  const history = useHistory()
  const [company, setCompany] = useState<ICompany | null>(null)
  const [community, setCommunity] = useState<ICommunity | null>(null)
  const [communities, setCommunities] = useState<ICommunity[]>([])
  const [communityFilteredOptions, setOptions] = useState<Department[]>([])
  const [mappings, setMappings] = useState<IncomingJobMapInterface[]>([])
  const [modifiedRow, setModifiedRow] = useState<boolean[]>([])
  const [showError, setShowError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [confirmSaveAlRowsOpen, setConfirmSaveAllRowsOpen] = useState<boolean>(false)
  const [applyToAllCommunities, setApplyToAllCommunties] = useState<boolean>(false)
  const [showLoading, setShowLoading] = useState<boolean>(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [confirmReplaceExistingMappings, setConfirmReplaceExistingMappings] = useState<boolean>(false)

  const communityId = useMemo(() => {
    return community?.id
  }, [community])

  const communityFilteredOptionsLength = useMemo(() => {
    return communityFilteredOptions ? communityFilteredOptions.length : 0
  }, [communityFilteredOptions])

  // method checks to see whether the mapping type should be filtered by community or company
  const filteredByCompany = useMemo(
    () => history.location.state === MapTypeEnum.FacilityMapping,
    [history.location.state]
  )

  // will get corresponding maps by checking the type in second param of 'GetMaps()'
  const fetchMappings = useCallback(
    async (id: string) => {
      if (!id) {
        return
      }

      let innerMappings: IncomingJobMapInterface[]

      try {
        setShowLoading(true)
        innerMappings = await McpApiService.GetMaps(id, history.location.state)
      } finally {
        setShowLoading(false)
      }

      let modifiedRowsInit: boolean[] = []

      if (innerMappings) {
        innerMappings.forEach(() => modifiedRowsInit.push(false))
      } else {
        innerMappings = []
      }

      setTimeout(() => {
        setModifiedRow(modifiedRowsInit)
        setMappings(innerMappings)
      }, 0)
    },
    [history.location.state]
  )

  // the following method will only run if we are filtering by community, otherwise 'fetchCommunities()'
  //  method will propagate the selector for the mapping row

  const fetchMappingSelectorData = useCallback(
    async (id?: string) => {
      if (!id) {
        return
      }
      try {
        setShowLoading(true)
        const departments = await McpApiService.GetDepartmentsByCommunity(id, history.location.state)
        setOptions(departments)
      } finally {
        setShowLoading(false)
      }
    },
    [history.location.state]
  )

  // the following method will run when a company has been selected and will either propagate the mapping
  //  selector if mappings grouped by company or the community selector if grouped by community
  const fetchCommunities = useCallback(
    async (company: ICompany | null) => {
      setCommunity(null)

      if (company) {
        let foundCommunities: ICommunity[]

        try {
          setShowLoading(true)
          foundCommunities = await McpApiService.GetUserCommunitiesByCompany(company.id)
        } finally {
          setShowLoading(false)
        }

        setCommunities(foundCommunities)

        if (applyToAllCommunities && foundCommunities && foundCommunities.length > 0) {
          // Use the first community as the one to get the mapping selector data.
          fetchMappingSelectorData(foundCommunities[0].id)
        } else {
          setOptions([])
        }
      } else {
        setOptions([])
      }

      // Don't clear the mappings when filtering by company as this will re-set the mapping area (even though it shouldn't).
      if (!filteredByCompany) {
        setMappings([])
      }
    },
    [applyToAllCommunities, fetchMappingSelectorData, filteredByCompany]
  )

  const processError = useCallback((error: AxiosError) => {
    if (
      error.response?.status === 400 &&
      error.response.data.responseData.title === "One or more validation errors occurred."
    ) {
      // There was a data validation error. These are okay to show to the user as-is.
      // Attempt to parse out the validation error from the errors object.
      let messages: string = ""

      for (let errorValue of Object.values(error.response.data.responseData.errors)) {
        if (errorValue === null && errorValue === undefined) {
          continue
        } else if (Array.isArray(errorValue)) {
          // This error value is an array of strings.
          for (let innerMessage of errorValue) {
            messages = messages + `\r\n${innerMessage}`
          }
        } else if (typeof errorValue === "object") {
          messages = messages + `\r\n${JSON.stringify(errorValue)}`
        } else {
          messages = messages + `\r\n${errorValue}`
        }
      }

      messages = messages.trim()
      setErrorMessage(`Error while inserting: ${messages}`)
    } else if (error.response?.data.responseData) {
      setErrorMessage(`Error while inserting: ${JSON.stringify(error.response?.data.responseData)}`)
    } else if (error.response?.data.errors) {
      // General error data
      let messages: string = ""

      for (let errorObject of error.response.data.errors) {
        messages = messages + `\r\n${errorObject.ErrorObject}`
      }

      messages = messages.trim()
      setErrorMessage(`Error while inserting: ${messages}`)
    } else {
      setErrorMessage(`Error while inserting: An unknown error has occurred`)
    }

    setShowError(true)
  }, [])

  // THE FOLLOWING METHODS ARE UNIVERSAL FOR MCP/DENALI MAPPINGS
  //--------------------------------------------------------------------
  const addNewMapping = () => {
    if (!communityFilteredOptions || (!communities && filteredByCompany)) return
    const newMapping: IncomingJobMapInterface = {
      id: undefined,
      customerCode: "",
      tableId: "",
      companyId: filteredByCompany ? company?.id : "",
      communityId: !filteredByCompany ? communityId : ""
    }
    setMappings([...mappings, newMapping])
    setModifiedRow([...modifiedRow, true])
  }

  const updateMapping = (key: any, mapping: IncomingJobMapInterface) => {
    let updatedMappings = [...mappings]
    updatedMappings[key] = mapping
    setMappings(updatedMappings)
    let modifiedRows = [...modifiedRow]
    modifiedRows[key] = true
    setModifiedRow(modifiedRows)
  }

  const deleteMapping = async (key: any, mapping: IncomingJobMapInterface, deleteAllMatching: boolean) => {
    const deletedMap = McpApiUtils.MapTypeSetter(history.location.state as MapTypeEnum, mapping)

    if (mapping.id) {
      if (deleteAllMatching) {
        let request = {
          companyId: company?.id,
          mappings: [deletedMap.map],
          affectedCommunities: communities?.map((x) => x.id)
        } as IBulkRequest

        try {
          setShowLoading(true)
          const result = await McpApiService.DeleteBulkMap(deletedMap.pathname, request)

          if (!result) {
            return
          }
        } finally {
          setShowLoading(false)
        }
      } else {
        const result = await McpApiService.DeleteMap(deletedMap)
        if (!result) {
          return
        }
      }
    }

    const updatedMappings = mappings.filter((m) => m !== mapping)
    setMappings(updatedMappings)

    let modifiedRows = [...modifiedRow]
    modifiedRows.splice(key, 1)
    setModifiedRow(modifiedRows)
  }

  const saveMapping = useCallback(
    async (key: any, mapping: IncomingJobMapInterface) => {
      let newMap: APIMapInterface
      const type = history.location.state as MapTypeEnum
      try {
        if (mapping.id) {
          newMap = McpApiUtils.MapTypeSetter(type, mapping)

          try {
            setShowLoading(true)
            McpApiService.UpdateMap(newMap)
          } finally {
            setShowLoading(false)
          }
        } else {
          newMap = McpApiUtils.MapTypeSetter(type, mapping)

          let result: any
          try {
            setShowLoading(true)
            result = await McpApiService.PostMap(newMap)
          } finally {
            setShowLoading(false)
          }

          let updatedMappings = [...mappings]
          updatedMappings[key].id = result.id
          setMappings(updatedMappings)
        }

        let modifiedRows = [...modifiedRow]
        modifiedRows[key] = false
        setModifiedRow(modifiedRows)
      } catch (ex) {
        let error = ex as AxiosError
        processError(error)
      }
    },
    [history.location.state, mappings, modifiedRow, processError]
  )

  const saveAllRows = useCallback(async () => {
    if (mappings && mappings.length > 0) {
      const type = history.location.state as MapTypeEnum

      try {
        // Get a APIMapInterface for the first mapping to get path names and other settings.
        let pathName: string = ""
        const convertedMappings: Array<
          IncomingJobMapInterface | FacilityInterface | JobCodeInterface | PayCodeInterface | FlsaCodeInterface
        > = []

        for (const mapping of mappings) {
          // Cannot explicitly look for falsey here as some mappings can be 0
          // which is a valid value
          if (
            mapping.customerCode.trim() === "" ||
            mapping.tableId === null ||
            mapping.tableId === undefined ||
            mapping.tableId === "" ||
            (typeof mapping.tableId === "string" && mapping.tableId.trim() === "")
          ) {
            setShowError(true)
            setErrorMessage("Please remove any blank mappings before saving all records")
            return
          }

          let newMap = McpApiUtils.MapTypeSetter(type, mapping)

          if (pathName === "") {
            pathName = newMap.pathname
          }

          if (newMap.map) {
            convertedMappings.push(newMap.map)
          }
        }

        let result: any

        let request = {
          companyId: company?.id,
          mappings: convertedMappings,
          affectedCommunities: applyToAllCommunities ? communities?.map((x) => x.id) : [communityId]
        } as IBulkRequest

        if (filteredByCompany && company) {
          request.affectedCommunities = [company.id]
        }

        try {
          setShowLoading(true)
          result = await McpApiService.PostBulkMap(pathName, request)
        } finally {
          setShowLoading(false)
        }

        let updatedMappings = [...mappings]
        let modifiedRows = [...modifiedRow]

        // Update the mappings and row state with the save results when successful.
        for (let i = 0; i < updatedMappings.length; i++) {
          updatedMappings[i].id = result[i].id
          modifiedRows[i] = false
        }

        setMappings(updatedMappings)
        setModifiedRow(modifiedRows)
      } catch (ex) {
        let error = ex as AxiosError
        processError(error)
      }
    }
  }, [
    applyToAllCommunities,
    communities,
    communityId,
    company,
    filteredByCompany,
    history.location.state,
    mappings,
    modifiedRow,
    processError
  ])

  const setOpen = (value: boolean): void => {
    setShowError(value)
  }

  const setAffectsAllCommunities = useCallback(
    (value: boolean): void => {
      setApplyToAllCommunties(value)

      if (company && (!communityFilteredOptions || communityFilteredOptions.length === 0)) {
        fetchCommunities(company)
      }
    },
    [communityFilteredOptions, company, fetchCommunities]
  )

  const updateCompany = useCallback(
    (company: ICompany | null) => {
      setCompany(company)

      if (company || !filteredByCompany) {
        fetchCommunities(company)

        if (company) {
          fetchMappings(company.id)
        } else {
          setMappings([])
        }
      }
    },
    [fetchCommunities, fetchMappings, filteredByCompany]
  )

  const updateCommunity = useCallback(
    (community: ICommunity | null) => {
      setCommunity(community)

      if (!filteredByCompany) {
        if (community) {
          fetchMappingSelectorData(community.id)

          fetchMappings(community.id)
        } else {
          // Clear out the mappings as the community was cleared.
          setMappings([])
        }
      }
    },
    [fetchMappingSelectorData, fetchMappings, filteredByCompany]
  )

  const showMappingsGrid = () => {
    return (
      (!filteredByCompany && communityFilteredOptionsLength > 0 && community !== null) ||
      (filteredByCompany && communities.length > 0 && company !== null) ||
      (filteredByCompany && company !== null && company !== undefined && applyToAllCommunities)
    )
  }

  const enableUploadButton = () => {
    /* 
      There must be no mappings defined
      And: 
        We must have a company selected (when a facilities screen)
        OR We must have a community selected (when not a facilities screen)
    */
    return (
      ((!filteredByCompany && communityFilteredOptionsLength > 0 && community !== null) ||
        (filteredByCompany && company !== null)) &&
      (mappings === null || mappings === undefined || mappings.length === 0)
    )
  }

  const parseSelectedFile = useCallback(
    (eventFile?: File) => {
      // Use the passed in file if provided, otherwise use the value from the rendered state as we had to wait for the confirmation.
      let processFile = !eventFile ? selectedFile : eventFile

      if (processFile) {
        try {
          setShowLoading(true)

          Papa.parse(processFile, {
            header: true,
            skipEmptyLines: true,
            complete: function (results: Papa.ParseResult<object>) {
              try {
                let innerMappings = new Array<IncomingJobMapInterface>()
                let innerModifiedRows = new Array<boolean>()

                // Go through each row of the CSV file and add the mapping item.
                for (let item of results.data) {
                  let keys = Object.keys(item)

                  // Don't trust that the customer kept the prescribed header value,
                  // Just process by the array of keys instead of hard-coded keys.
                  for (let key of keys) {
                    let customerValue = (item as any)[key] as string

                    // Make sure when we read the file value that it is not empty or just white-space as this is not valid.
                    if (customerValue && customerValue.trim()) {
                      innerMappings.push({
                        customerCode: (item as any)[key],
                        companyId: company?.id,
                        communityId: community?.id
                      } as IncomingJobMapInterface)

                      innerModifiedRows.push(true)
                    }

                    // We only allow for a single column currently so break out right away.
                    break
                  }
                }

                setMappings(new Array<IncomingJobMapInterface>())

                // Put the mappings on a timeout so the UI has a chance to remove the old rows
                // Without doing this, there is a chance of a UI artifact where old records
                // can be seen on the UI when the user scrolls to items which were previously
                // outside of the current view.
                setTimeout(() => {
                  setMappings(innerMappings)
                  setModifiedRow(innerModifiedRows)
                }, 0)
              } catch (error) {
                setErrorMessage(`The selected file is not in the correct format`)
                setShowError(true)

                console.error("There was an error while processing the CSV file contents", error)
              } finally {
                setShowLoading(false)
              }
            }
          })
        } catch (error) {
          // Note: This isn't in a finally as we don't want to stop showing the loading indicator
          // Until the processing of the row data is complete.
          setShowLoading(false)

          setErrorMessage(`The selected file is not in the correct format`)
          setShowError(true)

          console.error("There was an error while parsing the CSV file", error)
        }
      }
    },
    [community, company, selectedFile]
  )

  const onFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        setSelectedFile(event.target.files[0])

        let innerSelectedFile = event.target.files[0]

        // Make sure this is the correct file type.
        if (!innerSelectedFile || !innerSelectedFile.name.endsWith(".csv")) {
          setErrorMessage(`The selected file is not in the correct format`)
          setShowError(true)
          return
        }

        if (mappings.length > 0) {
          setConfirmReplaceExistingMappings(true)
        } else {
          parseSelectedFile(event.target.files[0])
        }
      }
    },
    [mappings, parseSelectedFile]
  )

  //--------------------------------------------------------------------

  return (
    <div key="mainContainer" style={pageStyles.mainPage}>
      <div key="topSelector" style={filteredByCompany ? pageStyles.topSelectorFacilities : pageStyles.topSelector}>
        <CommunitySelector
          key="communitySelector"
          company={company}
          setCompany={updateCompany}
          type={history.location.state}
          community={community}
          setCommunity={updateCommunity}
          setAffectsAllCommunities={setAffectsAllCommunities}
          onFileChange={onFileChange}
          enableUploadButton={enableUploadButton() ? true : false}
        />
        {((community === null && !filteredByCompany && !applyToAllCommunities) ||
          (community === null && company && applyToAllCommunities)) && (
          <Typography key="communityWarning" variant="h6" align="center">
            Please Select a Community
          </Typography>
        )}
        {company === null && (filteredByCompany || applyToAllCommunities) && (
          <Typography key="companyWarning" variant="h6" align="center">
            Please Select a Company
          </Typography>
        )}
        <WithErrorHandling key="errorDialog" open={showError} setOpen={setOpen} errorMessage={String(errorMessage)} />
      </div>
      {showMappingsGrid() && (
        <>
          <Grid
            key="gridContainer"
            container
            style={filteredByCompany ? pageStyles.mappingSectionFacilities : pageStyles.mappingSection}
          >
            {mappings.map((mapping, key) => (
              <IncomingJobMapRow
                type={history.location.state}
                mapping={mapping}
                index={key}
                key={`jobMapRow_${key}`}
                communities={communities}
                communityFilteredOptions={communityFilteredOptions}
                showSave={!applyToAllCommunities}
                applyToAllCommunities={applyToAllCommunities}
                filteredByCompany={() => filteredByCompany}
                modified={modifiedRow[key]}
                onChange={updateMapping}
                onDelete={deleteMapping}
                onSave={saveMapping}
              />
            ))}
          </Grid>
          <div key="bottomBar" style={pageStyles.bottomBar}>
            <div key="buttonBox" style={styles.box}>
              <Button
                key="addMappingButton"
                variant="contained"
                color="primary"
                onClick={addNewMapping}
                id="addMapping"
              >
                <Add />
                Add Mapping
              </Button>
              <Button
                key="saveAllRowButton"
                style={pageStyles.saveAllRowsButton}
                variant="contained"
                color="primary"
                onClick={() => setConfirmSaveAllRowsOpen(true)}
                id="saveAllRows"
              >
                <Save />
                Save All Rows
              </Button>
              <ConfirmDialog
                key="saveAllRowsConfirmation"
                title="Save All Rows?"
                open={confirmSaveAlRowsOpen}
                setOpen={setConfirmSaveAllRowsOpen}
                onConfirm={saveAllRows}
              >
                {applyToAllCommunities && !filteredByCompany ? (
                  <>
                    <p style={pageStyles.dialogText}>
                      Saving all current mappings will remove all configured maps for all communities and replace with
                      the values supplied.
                    </p>
                    <p style={pageStyles.dialogText}>Are you sure you want to continue?</p>
                  </>
                ) : (
                  <>
                    <p style={pageStyles.dialogText}>Are you sure you want to save all records?</p>
                  </>
                )}
              </ConfirmDialog>
            </div>
          </div>
        </>
      )}
      <Backdrop key="progressBackdrop" style={pageStyles.backdrop} open={showLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <ConfirmDialog
        key={`$csvUploadConfirmation`}
        title="Replace Existing Mappings?"
        open={confirmReplaceExistingMappings}
        setOpen={setConfirmReplaceExistingMappings}
        onConfirm={() => parseSelectedFile()}
      >
        <>
          <p style={pageStyles.dialogText}>This will replace all existing mappings defined on the screen.</p>
          <p style={pageStyles.dialogText}>Are you sure you want to continue?</p>
        </>
      </ConfirmDialog>
    </div>
  )
}
