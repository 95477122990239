import { IADPSubscriptionMapping } from "models/IADPSubscriptionMapping"
import { REACT_APP_API_BASE } from "services/Api/ApiBaseRouteConstants"
import { ADP_SUBSCRIPTION_MAPPING_URI } from "services/Api/ADPSubscriptionMappingConstants"
import { ApiWrapper } from "./AxiosWrapper"

class ADPSubscriptionMappingAPI {
  public async GetADPMappings(): Promise<IADPSubscriptionMapping[]> {
    return ApiWrapper.get(REACT_APP_API_BASE + ADP_SUBSCRIPTION_MAPPING_URI, {})
  }

  public async GetADPMapping(id: string): Promise<IADPSubscriptionMapping> {
    let adpMapping: IADPSubscriptionMapping = await ApiWrapper.get(
      `${REACT_APP_API_BASE}${ADP_SUBSCRIPTION_MAPPING_URI}${id}`,
      {}
    )
    // Make sure that savedAt is strictly a Date
    if (adpMapping && adpMapping?.modified !== null) {
      adpMapping.modified = new Date(adpMapping.modified)
    }
    return adpMapping
  }

  public async PostADPMapping(adpMapping: IADPSubscriptionMapping): Promise<IADPSubscriptionMapping> {
    return await ApiWrapper.post(REACT_APP_API_BASE + ADP_SUBSCRIPTION_MAPPING_URI, adpMapping, {})
  }

  public async UpdateADPMapping(adpMapping: IADPSubscriptionMapping): Promise<IADPSubscriptionMapping> {
    adpMapping.modified = new Date(Date.now())
    let updatedADPMapping: IADPSubscriptionMapping = await ApiWrapper.put(
      `${REACT_APP_API_BASE}${ADP_SUBSCRIPTION_MAPPING_URI}${adpMapping.id}`,
      adpMapping,
      {}
    )
    if (adpMapping && updatedADPMapping?.modified !== null) {
      updatedADPMapping.modified = new Date(updatedADPMapping.modified)
    }
    return adpMapping
  }

  public async DeleteADPMapping(id: string): Promise<IADPSubscriptionMapping> {
    return ApiWrapper.delete(`${REACT_APP_API_BASE}${ADP_SUBSCRIPTION_MAPPING_URI}${id}`, null, {})
  }
}

export const ADPSubscriptionMappingAPIService: ADPSubscriptionMappingAPI = new ADPSubscriptionMappingAPI()
